import { render, staticRenderFns } from "./PermissionManage.vue?vue&type=template&id=cfefe422&scoped=true"
import script from "./PermissionManage.vue?vue&type=script&lang=js"
export * from "./PermissionManage.vue?vue&type=script&lang=js"
import style0 from "./PermissionManage.vue?vue&type=style&index=0&id=cfefe422&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_babel-core@7.0.0-bridge.0_@babel+core@7.26._c88bc1a1b3645f9aa299062c1f87bcb1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfefe422",
  null
  
)

export default component.exports